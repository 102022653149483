<template>
  <div>
    <SourceScreen
      :openType="openType"
      :openCategory="openCategory"
      :openStatus="openStatus"
      :filterForm="filterForm"
      :selectArr="selectArr"
      :page="pageData.current_page"
      :uploadFilter="ok"
    >
    </SourceScreen>
    <div class="brand-content" v-loading="show_loading">
      <el-table
        class="thead-light"
        :data="list"
        tooltip-effect="dark"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- <el-table-column prop="id" label="ID" min-width="70"></el-table-column> -->

        <el-table-column
          prop=""
          label="用户名称"
          min-width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.nickname | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="会内职务"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.title_name | placeholder }}
          </template>
        </el-table-column>
        <!--                 金额、积分 -->
        <el-table-column
          prop="price"
          label="交易数额"
          sortable="custom"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.price | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="order_type"
          label="交易类型"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="activity_name"
          label="活动名称"
          min-width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="orderid"
          label="订单号"
          min-width="170"
        ></el-table-column>
        <!--                 付费、保证金 -->
        <el-table-column
          prop="order_category"
          label="订单类型"
          min-width="165"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- 已支付、未支付、已取消、已退款 -->
        <el-table-column min-width="100" label="订单状态">
          <template slot-scope="{ row }">
            <ListStatusText
              :text="row.order_status"
              :type="
                row.order_status === '已支付'
                  ? 'success'
                  : row.order_status === '未支付'
                  ? 'warning'
                  : row.order_status === '已退款'
                  ? 'danger'
                  : 'info'
              "
            ></ListStatusText>
          </template>
        </el-table-column>
        <el-table-column prop="merchant" label="商户号" min-width="120" />
        <el-table-column
          prop="create_time"
          label="创建时间"
          sortable="custom"
          min-width="160"
        />
        <el-table-column label="操作" fixed="right" width="130">
          <template slot-scope="scope">
            <el-button
              @click="updataDetail(scope.row.id, scope.row.status)"
              type="text"
              >查看</el-button
            >
            <!--
            订单状态  0：已支付，1：未支付，2：已取消，3：已退款，4:退款中
            -->
            <el-button
              v-if="scope.row.status === 1"
              @click="cancel(scope.row.orderid)"
              type="text"
              >取消订单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="订单详情"
        width="750px"
        class="dialog-vertical"
        :visible.sync="showTransfer"
      >
        <div v-loading="dialogLoading">
          <el-row :gutter="20">
            <el-col :span="8"
              >会员名称 :
              {{ detailList.user ? detailList.user.nickname : "-" }}</el-col
            >
            <el-col :span="16"
              >会内职务 :
              {{ detailList.user ? detailList.user.title_name : "-" }}</el-col
            >
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">交易数额 : {{ detailList.price }}（元）</el-col>
            <el-col :span="8">交易类型 : 金额</el-col>
            <el-col :span="8"
              >活动名称 :
              {{ detailList.activity ? detailList.activity.name : "-" }}</el-col
            >
          </el-row>
          <el-divider></el-divider>

          <el-row :gutter="20">
            <el-col :span="8"
              >订单类型 : {{ detailList.order_category }}</el-col
            >
            <el-col :span="8">订单号 : {{ detailList.orderid }}</el-col>
            <el-col :span="8">订单状态 : {{ detailList.order_status }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8" v-if="detailList.status == 3"
              >退款金额 : {{ detailList.refund_price }}（元）</el-col
            >
            <el-col :span="16"
              >备注 :
              {{
                detailList.refund_remarks ? detailList.refund_remarks : "-"
              }}</el-col
            >
          </el-row>
          <el-divider></el-divider>

          <el-row :gutter="20">
            <!-- 已取消 -->
            <el-col :span="8" v-if="detailList.status == 2"
              >取消时间 : {{ detailList.cancel_time }}</el-col
            >
            <!-- 已退款 -->
            <el-col :span="8" v-else-if="detailList.status == 3">
              <div class="el-title">退款时间 :</div>
              {{ detailList.refund_time }}
            </el-col>
            <!-- 已支付 -->
            <el-col :span="8" v-else-if="detailList.status == 0"
              >支付时间 :
              {{ detailList.pay_time ? detailList.pay_time : "-" }}</el-col
            >
            <el-col :span="8">商户号 : {{ detailList.merchant }}</el-col>
            <el-col :span="8">创建时间 : {{ detailList.create_time }}</el-col>
          </el-row>
        </div>
        <el-divider></el-divider>
        <template #footer>
          <el-button size="small" type="primary" @click="showTransfer = false"
            >确认</el-button
          >
        </template>
      </el-dialog>
    </div>
    <Pagination
      v-show="!show_loading"
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
  </div>
</template>

<script>
import SourceScreen from "../components/CycActivityOrder/SourceScreen";
import Pagination from "@/base/components/Default/Pagination";
import {
  indexList,
  getDetail,
  cancelOrder,
} from "../../activity/api/cyc-activity-order";
import ListStatusText from "@/base/components/List/ListStatusText";

export default {
  components: {
    SourceScreen,
    Pagination,
    ListStatusText,
  },
  data() {
    return {
      show_loading: false,
      showTransfer: false,
      dialogLoading: false,
      list: [],
      total: 0,
      detailList: [],
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "",
        merchant: "",
        status: -1,
        category: -1,
        type: -1,
        page_size: 15,
      },
      pageData: {},
      selectArr: [],
      openStatus: [
        {
          name: "已支付",
          id: 0,
        },
        {
          name: "未支付",
          id: 1,
        },
        {
          name: "已取消",
          id: 2,
        },
        {
          name: "已退款",
          id: 3,
        },
        {
          name: "退款中",
          id: 4,
        },
      ],
      openCategory: [
        {
          name: "活动报名（付费报名）",
          id: 1,
        },
        {
          name: "活动报名（保证金报名）",
          id: 4,
        },
      ],
      openType: [
        {
          name: "金钱",
          id: 0,
        },
        {
          name: "积分",
          id: 1,
        },
      ],
    };
  },
  created() {
    this.getIndexList(this.filterForm);
  },
  methods: {
    cancel(id) {
      cancelOrder({ orderid: id })
        .then(() => {
          this.getIndexList(this.filterForm);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //页面切换
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      const pageData = { ...this.filterForm, ...data };
      this.getIndexList(pageData);
    },
    //获取列表
    getIndexList(requestData) {
      this.show_loading = true;
      this.pageData = {};
      indexList(requestData)
        .then((res) => {
          const { data } = res;
          this.list = data.data;
          this.pageData = data;
          this.show_loading = false;
        })
        .catch((err) => {
          this.show_loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...e, page: 1 };
      this.getIndexList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getIndexList(this.filterForm);
    },
    updataDetail(id, status) {
      this.showTransfer = true;
      this.dialogLoading = true;
      getDetail({ id: id, status: status })
        .then((res) => {
          this.detailList = res.data;
          this.dialogLoading = false;
        })
        .catch((err) => {
          this.dialogLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-content {
  margin-top: 20px;
  .el-col-8 {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .el-col-16 {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .el-divider--horizontal {
    margin: 0;
  }
  .el-title {
    width: 70px;
  }
}
</style>
