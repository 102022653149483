import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 列表数据
export const indexList = data => {
  return api({
    url: '/admin/cyc_activity/order/index',
    method: 'post',
    data
  });
};
//查看数据
export const getDetail = data => {
  return api({
    url: '/admin/cyc_activity/order/detail',
    method: 'post',
    data
  });
};

// 导出订单列表
export const documentOrder = data => {
  return download({
    url: "/admin/cyc_activity/order/export",
    method: "post",
    data,
  });
};

export const cancelOrder = data => {
  return api({
    url: '/admin/cyc_activity/order/cancelOrder',
    method: 'post',
    data
  });
}
