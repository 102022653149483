<template>
  <LayoutFilter :onFilter="ok" :toggleCollapseEnable="form.user_keyword !== undefined"
                :onReset="resetForm" :onExport="form.user_keyword !== undefined ? onExport : null">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from grid" @submit.native.prevent>

      <!-- 模块&用户名&搜索 -->
<!--      <div class="filter-item">-->
        <el-form-item v-if="form.user_keyword !== undefined" label="用户名称：" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type !== undefined" label="交易类型：" prop="type">
          <el-select v-model="type" placeholder="交易类型">
            <el-option label="全部" :value="-1"></el-option>
            <el-option v-for="item in openType" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.orderid !== undefined" label="订单号：" prop="orderid">
          <el-input v-model="form.orderid" placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item v-if="form.orderids !== undefined" label="订单号：" prop="orderids">
          <el-input v-model="form.orderids" placeholder="请输入订单号"></el-input>
        </el-form-item>
<!--      </div>-->
<!--      <div class="filter-item">-->
        <el-form-item v-if="form.category !== undefined" label="订单类型：" prop="category">
          <el-select v-model="category" placeholder="订单类型">
            <el-option label="全部" :value="-1"></el-option>
            <el-option v-for="item in openCategory" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：" prop="status">
          <el-select v-model="status" placeholder="订单状态">
            <el-option label="全部" :value="-1"></el-option>
            <el-option v-for="item in openStatus" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.merchant !== undefined" label="商户号：" prop="merchant">
          <el-input v-model="form.merchant" placeholder="请输入商户号"></el-input>
        </el-form-item>
<!--      </div>-->
<!--      <div class="filter-item">-->
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" style="grid-column: 1 / span 2"
        />
<!--      </div>-->
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentOrder } from "../../api/cyc-activity-order.js";
import { aElementDownload } from "@/base/utils/tool";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    selectArr: {
      type: Array,
      default: () => [],
    },
    openType: {
      type: Array,
    },
    openCategory: {
      type: Array,
    },
    openStatus: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
    // onExport: {
    //   type: Function,
    // },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      const postData = { ...this.form, ids: this.selectArr, page: this.page };
      return documentOrder(postData)
        .then(([blob, fileName]) => {
          aElementDownload(blob, fileName);
        })
        .catch(() => {});
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    type: {
      get() {
        return this.form.type == -2 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
    category: {
      get() {
        return this.form.category == -2 ? "" : this.form.category;
      },
      set(val) {
        this.form.category = val;
      },
    },
    status: {
      get() {
        return this.form.status == -2 ? "" : this.form.status;
      },
      set(val) {
        this.form.status = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
